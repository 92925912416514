// Path: packages/flex/src/tabs/tabs.tsx

import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { TabsProperties } from './tabs.types';
import { Text } from '../Text';
import { Select } from '../Select';

/**
 *
 * The tabs component is used to organize content into different sections that are displayed one at a time.
 * @group Components
 */
export const Tabs = ({ tabs = [], className, currentTab }: TabsProperties) => {
  const formatTabForSelect = (tab?: TabsProperties['tabs'][0]) => {
    if (tab?.id) return { value: tab.id, label: tab.name, selected: tab.count };
  };

  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <Select
          name="tabs"
          options={tabs.map((tab) => ({
            value: tab.id,
            label: tab.name,
          }))}
          defaultValue={formatTabForSelect(tabs.find((tab) => tab.id === currentTab))}
          onValueChange={(value) => {
            if (value && 'value' in value) {
              // because we know here that value will never be a MultiValue
              tabs.find((tab) => tab.id === value.value)?.onClick?.(value.value);
            }
          }}
        />
      </div>
      <div className="hidden sm:!block">
        <div className="border-b border-slate-200 [border-bottom-style:solid]">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => {
              const isSelected = tab.id === currentTab;

              return (
                <div className="relative">
                  <button
                    key={tab.name}
                    onClick={() => tab.onClick?.(tab.id)}
                    className={clsx(
                      'border-transparent hover:border-slate-200',
                      'group flex whitespace-nowrap border-b-2 px-1 py-2 text-sm font-medium transition-colors [border-bottom-style:solid]',
                    )}
                    aria-current={isSelected ? 'page' : undefined}
                  >
                    <Text
                      as="span"
                      className={clsx(
                        isSelected ? 'text-primary-600' : 'text-slate-500 group-hover:text-slate-700',
                      )}
                      strong="medium"
                    >
                      {tab.name}
                    </Text>
                    {tab.count ? (
                      <Text
                        as="span"
                        className={clsx(
                          isSelected
                            ? 'bg-primary-100 text-primary-600'
                            : 'bg-slate-100 text-slate-900',
                          'ml-2 hidden rounded-full px-3 py-0.5 text-xs font-medium transition-colors md:!inline-block',
                        )}
                        size="xs"
                      >
                        {tab.count}
                      </Text>
                    ) : undefined}
                  </button>

                  {isSelected && (
                    <motion.div
                      className="bg-primary-500 absolute bottom-0 z-10 h-px w-full"
                      transition={{ duration: 0.2 }}
                      layoutId="activeBar"
                      layout
                    />
                  )}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};
